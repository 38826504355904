import domReady from '@wordpress/dom-ready';

domReady(() => {
  const popup = document.getElementById('popup');

  if (popup) {
    const closeBtn = document.querySelector('.close-btn');

    closeBtn.onclick = function () {
      popup.style.display = 'none';
    };
  }
});
