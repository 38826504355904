(function ($) {
  // (!) These classes and selector also need to be changed in the _dropdown-menu.scss (!)

  const navWithDropdown = $('.nav-primary');

  const dropdownContainerSelector = '.dropdown';

  const dropdownToggleClass = 'dropdown__toggle';
  const activeClass = 'opened';

  const openedSubnav = {};

  let uniqueNameCount = 0;

  function createUniqueSubnavName() {
    uniqueNameCount++;

    return 'subnav_' + uniqueNameCount;
  }

  function tryClosingSubNav(event) {
    for (const subnavName in openedSubnav) {
      if ('undefined' === typeof subnavName) {
        continue;
      }

      const $target = openedSubnav[subnavName].find(event.target);
      if (!$target.length) {
        /* jshint ignore:start */
        closeSubnav(subnavName);
        /* jshint ignore:end */

        continue;
      }
    }
  }

  function getOpenedSubnavByName(subnavName) {
    if (!openedSubnav[subnavName]) {
      console.warn(
        subnavName + " n'existe pas encore. (getOpenedSubnavByName())"
      );
      return $();
    }

    return openedSubnav[subnavName];
  }

  function getSubnavName($subnav) {
    let subnavName = $subnav.data('subnav');

    if (!subnavName) {
      subnavName = createUniqueSubnavName();
      $subnav.data('subnav', subnavName);
    }

    return subnavName;
  }

  function setOpenedSubnav($subnav) {
    const subnavName = getSubnavName($subnav);

    openedSubnav[subnavName] = $subnav;
  }

  function openSubnav(subnavName) {
    const $subnav = getOpenedSubnavByName(subnavName);
    $subnav.addClass(activeClass);

    // Items are not visible for the moment and also not targetable by that fact.
    // This delay will permit a focus.
    const $toggle = $subnav.find('.' + dropdownToggleClass).first();
    $toggle
      .attr('aria-expanded', true)
      .attr('aria-label', $toggle.data('label-close'));

    setTimeout(
      function () {
        this.find('li a').first().focus();

        if (1 === Object.keys(openedSubnav).length) {
          $(document)
            .on('focusin.closing_subnav', tryClosingSubNav)
            .on('click.closing_subnav', tryClosingSubNav);
        }
      }.bind($subnav),
      10
    );
  }

  function closeSubnav(subnavName) {
    const $subnav = getOpenedSubnavByName(subnavName);
    $subnav.removeClass(activeClass);
    const $toggle = $subnav.find('.' + dropdownToggleClass).first();
    $toggle
      .attr('aria-expanded', false)
      .attr('aria-label', $toggle.data('label-open'));

    // Supprimer les événements si il n'y a plus de menu ouvert.
    if (!Object.keys(openedSubnav).length) {
      $(document).off('click.closing_subnav focusin.closing_subnav');
    }
  }

  /////////////// ON READY /////////////////
  $(function () {
    $('.nav-primary > .nav-item.dropdown')
      .on('mouseenter', function () {
        $(this).children('.subnav-wrapper-depth-0').addClass('opened');
      })
      .on('mouseleave', function () {
        $(this).children('.subnav-wrapper-depth-0').removeClass('opened');
      });

    navWithDropdown
      .find('.' + dropdownToggleClass)
      .on('click focusin', function (event) {
        event.stopPropagation();

        const $dropdownContainer = $(this).closest(dropdownContainerSelector);

        if (!$dropdownContainer.hasClass(activeClass)) {
          setOpenedSubnav($dropdownContainer);

          openSubnav(getSubnavName($dropdownContainer));
        } else {
          closeSubnav(getSubnavName($dropdownContainer));
        }
      })
      .on('focusin', function () {
        const $dropdownContainer = $(this).closest(dropdownContainerSelector);
        if ($dropdownContainer.hasClass(activeClass)) {
          closeSubnav(getSubnavName($dropdownContainer));
        }
      });

    // Ajout de la gestion de la touche Échap pour fermer le menu
    $(document).on('keydown', function (event) {
      if (event.key === 'Escape') {
        $.each(openedSubnav, function (subnavName, $subnav) {
          closeSubnav(subnavName);
          const $toggle = $subnav.find('.' + dropdownToggleClass).first();
          $toggle.focus(); // Ramener le focus sur le bouton de bascule
          $toggle.attr('aria-label', $toggle.data('data-label-close'));
        });
      }
    });
  });
})(jQuery);
