document.addEventListener('findstrInit', function () {
  const findstr = window.findstr || {};

  function checkFilters() {
    const filtersContainer = document.querySelector('.findstr__filters');

    if (filtersContainer) {
      const filtersActive =
        !!document.querySelector('.findstr__filters input:checked') ||
        !!document.querySelector('.findstr__filters input[type="search"]')
          .value ||
        Array.from(document.querySelectorAll('.findstr__filters select')).some(
          (select) => select.value
        );

      const hasFiltersActiveContainer = document.querySelector(
        '.accordion-pdf-listing__findstr-results'
      );

      const defaultListingContainer = document.querySelector(
        '.listing--accordion-pdf-listing'
      );

      if (hasFiltersActiveContainer && defaultListingContainer) {
        if (filtersActive) {
          hasFiltersActiveContainer.style.display = 'block';
          defaultListingContainer.style.display = 'none';
        } else {
          hasFiltersActiveContainer.style.display = 'none';
          defaultListingContainer.style.display = 'block';
        }
      }
    }
    const resetFilters = document.getElementById('reset-filters');

    if (resetFilters) {
      resetFilters.addEventListener('click', function () {
        findstr.resetFilters();
      });
    }
  }

  checkFilters();

  findstr.hooks.addAction('searchResults', 'checkFilters', function () {
    checkFilters();
  });
});
