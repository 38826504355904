import domReady from '@wordpress/dom-ready';

domReady(() => {
  const bodyContainer = document.querySelector('body');
  const alertsBarItems = document.querySelectorAll('.alerts-bar__item');

  if (alertsBarItems.length > 0) {
    bodyContainer.classList.add(`alerts-bar-active`);

    function heightAlertBar() {
      const alertsBar = document.getElementById('alerts-bar');
      const alertHeight = alertsBar.offsetHeight;
      alertsBar.style.setProperty('--AlertHeight', alertHeight + 'px');
      document.documentElement.style.setProperty(
        '--AlertHeight',
        alertHeight + 'px'
      );
    }

    alertsBarItems.forEach((alertItem) => {
      if (alertItem.classList.contains('alerts-bar-active')) {
        alertItem.style.transform = 'translateX(0)';
      } else {
        alertItem.style.transform = 'translateX(-100%)';
      }

      const closeBtn = alertItem.querySelector('.alerts-bar__close-button');
      closeBtn.addEventListener('click', () => toggleAlertBar(alertItem));
    });

    function toggleAlertBar(alertItem) {
      const isActive = alertItem.classList.toggle('alerts-bar-active');

      alertItem.style.transform = isActive
        ? 'translateX(0)'
        : 'translateX(calc(-100% + 50px))';
      updateIcon(alertItem, isActive);
      heightAlertBar();
    }

    function updateIcon(alertItem, isActive) {
      const iconSpan = alertItem.querySelector(
        '.alerts-bar__close-button__icon'
      );
      const newIconClass = alertItem
        .querySelector('.alerts-bar__icon')
        .className.split(' ')
        .find((cls) => cls.startsWith('icon-'));

      if (isActive) {
        // Lorsque l'alerte est ouverte, rétablir l'icône de fermeture
        iconSpan.classList.add('icon-close');
        iconSpan.classList.remove(newIconClass);
      } else {
        // Lorsque l'alerte est fermée, changer l'icône
        iconSpan.classList.remove('icon-close');
        iconSpan.classList.add(newIconClass);
      }
    }

    heightAlertBar();

    window.addEventListener('resize', heightAlertBar);
  }
});
